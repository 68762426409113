import Ripple from './Ripple';
import IcoMoreWhite from '@/assets/img/arrow_white_24x24@2x.png';
import IcoArrowDown from '@/assets/img/icons/arrow_sky@2x.png';
import IcoArrowDownWhite from '@/assets/img/icons/arrow_white@2x.png';
import IcoDown from '@/assets/img/icons/ico_down@2x.png';
import IcoDownWhite from '@/assets/img/icons/ico_down_white@2x.png';
import IcoMore from '@/assets/img/icons/ico_more_gray@2x.png';
import IcoShare from '@/assets/img/icons/ico_share@2x.png';
import IcoShareWhite from '@/assets/img/icons/ico_share_white@2x.png';
import { breakpoint, breakpointReverse } from '@/helpers/BreakpointHelper';
import React, { ButtonHTMLAttributes, FC } from 'react';
import styled, { css } from 'styled-components';

export const BtnClose = styled.a`
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 23px;
    height: 2px;
    background: #2d2926;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;
const MintDefault = css`
  color: #2d2926;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  /* 모바일에서는 버튼 호버스타일 제거 (한번 누르면 계속 호버상태) */
  ${breakpointReverse(`mobile`)} {
    &:not(:disabled):hover {
      background-color: #1cabe2;
      border-color: #1cabe2;
      color: #fff;
    }
  }
`;
const BlueDefault = css`
  color: #fff;
  background-color: #1cabe2;
  border-color: #1cabe2;
  ${breakpointReverse(`mobile`)} {
    &:not(:disabled):hover {
      background-color: #374ea2;
      border-color: #374ea2;
    }
  }
`;
const BlueOutline = css`
  color: #1cabe2;
  border-color: #1cabe2;
  ${breakpointReverse(`mobile`)} {
    &:not(:disabled):hover {
      color: #fff;
      background-color: #1cabe2;
      border-color: #1cabe2;
    }
  }
`;

const YellowDefault = css`
  color: #2d2926;
  background-color: #ffdc0e;
  border-color: #ffdc0e;
  ${breakpointReverse(`mobile`)} {
    &:not(:disabled):hover {
      background-color: #ffc20e;
      border-color: #ffc20e;
    }
  }
`;

const YellowOutline = css`
  color: #2d2926;
  border-color: #ffdc0e;
  ${breakpointReverse(`mobile`)} {
    &:not(:disabled):hover {
      color: #2d2926;
      background-color: #ffdc0e;
      border-color: #ffdc0e;
    }
  }
`;

const WhiteDefault = css<{ outline?: boolean }>`
  color: #2d2926;
  background-color: #fff;
  border-color: #fff;
  ${breakpointReverse(`mobile`)} {
    &:not(:disabled):hover {
      background-color: #e5e6e8;
      border-color: #e5e6e8;
    }
  }
`;

const WhiteOutline = css`
  color: #fff;
  border-color: #fff;
  ${breakpointReverse(`mobile`)} {
    &:not(:disabled):hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
`;

const GreyDefault = css<{ outline?: boolean }>`
  color: #fff;
  background-color: #828385;
  border-color: #828385;
  ${breakpointReverse(`mobile`)} {
    &:not(:disabled):hover {
      background-color: #5b595a;
      border-color: #5b595a;
    }
  }
`;

const GreyOutline = css`
  color: #828385;
  border-color: #828385;
  ${breakpointReverse(`mobile`)} {
    &:not(:disabled):hover {
      color: #fff;
      background-color: #828385;
      border-color: #828385;
    }
  }
`;

const GrayDefault = css<{ outline?: boolean }>`
  color: #fff;
  background-color: #b7b8ba;
  border-color: #b7b8ba;
`;

const GrayOutline = css`
  color: #828385;
  border-color: #b7b8ba;
  background: #fff;
  ${breakpointReverse(`mobile`)} {
    &:not(:disabled):hover {
      color: #fff;
      background-color: #828385;
      border-color: #828385;
    }
  }
`;

const WhiteGreyDefault = css<{ outline?: boolean }>`
  color: #828385;
  background-color: #fff;
  border-color: #828385;
  ${breakpointReverse(`mobile`)} {
    &:not(:disabled):hover {
      color: #fff;
      background-color: #5b595a;
      border-color: #5b595a;
    }
  }
`;

const WhiteGreyOutline = css`
  color: #828385;
  border-color: #828385;
  ${breakpointReverse(`mobile`)} {
    &:not(:disabled):hover {
      color: #fff;
      background-color: #828385;
      border-color: #828385;
    }
  }
`;

const WhiteSkyOutline = css<{ outline?: boolean }>`
  color: #1cabe2;
  background-color: #fff;
  border-color: #1cabe2;
  ${breakpointReverse(`mobile`)} {
    &:not(:disabled):hover {
      color: #fff;
      background-color: #1cabe2;
    }
  }
`;

interface ButtonElementProps {
  color?:
    | 'blue'
    | 'yellow'
    | 'white'
    | 'gray'
    | 'grey'
    | 'white-grey'
    | 'white-sky'
    | 'mint';
  size?: 'sm' | 'md' | 'lg';
  shape?: 'square' | 'round' | 'circle';
  outline?: boolean;
  full?: boolean;
  ico?: 'down' | 'share' | 'shareWhite' | 'more' | 'moreArrow';
  width?: number;
}

export type ButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'color'
> &
  ButtonElementProps;

const Button = styled<FC<ButtonProps>>(
  ({
    disabled,
    children,
    // 넘기면 안되는 prop들
    ico,
    size,
    shape,
    outline,
    full,
    width,
    ...props
  }) => (
    <button disabled={disabled} type="button" {...props}>
      {children}
      {!disabled && !ico && <Ripple />}
    </button>
  ),
)`
  font-size: 20px;
  padding: 12px 20px;
  box-sizing: border-box;
  font-weight: bold;
  border-width: 1px;
  border-style: solid;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  // Ripple을 위한 설정
  position: relative;
  overflow: hidden;

  &:hover {
    ${({ ico }) => {
      if (ico) {
        if (ico === `shareWhite`) {
          return `
            background-color: #1cabe2 ;
            border-color: #1cabe2 ;
            color:#fff ;
          `;
        }
        if (ico === `down`) {
          return `
            background-color: #1cabe2 ;
            border-color: #1cabe2 ;
            color:#fff ;
          `;
        }
        if (ico === `share`) {
          return `
            background-color: #1cabe2 ;
            border-color: #1cabe2 ;
            color:#fff ;
          `;
        }
        return `
        background-color: #1cabe2;
        border-color: #1cabe2;
        color:#fff;
        `;
      }
      return ``;
    }}
    .ico {
      &::before {
        ${({ ico }) => {
          if (ico === `moreArrow`) {
            return `
              background-image:url(${IcoArrowDownWhite});
            `;
          }
          if (ico === `down`) {
            return `
              background-image:url(${IcoDownWhite});
            `;
          }
          if (ico === `share`) {
            return `
              background-image:url(${IcoShareWhite});
            `;
          }
          if (ico === `more`) {
            return `
              background-image:url(${IcoMoreWhite});
            `;
          }
          return ``;
        }}
      }
    }
  }

  .ico {
    display: block;
    position: relative;
    width: 100%;
    padding-right: 24px;
    text-align: left;

    ${({ ico }) => {
      if (ico === `moreArrow`) {
        return `
          display: inline-block;
          width: auto;
        `;
      }
      return ``;
    }}
    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      ${({ ico }) => {
        if (ico === `down`) {
          return `
              background-image:url(${IcoDown});
            `;
        }
        if (ico === `share`) {
          return `
            background-image:url(${IcoShare});
          `;
        }
        if (ico === `shareWhite`) {
          return `
            background-image:url(${IcoShareWhite});
          `;
        }
        if (ico === `more`) {
          return `
              background-image:url(${IcoMore});
            `;
        }
        if (ico === `moreArrow`) {
          return `
              background-image:url(${IcoArrowDown});
              width: 32px;
              height: 32px;
              right: -4px;
            `;
        }
        return ``;
      }}
    }
  }

  ${breakpoint(640)} {
    font-size: 16px;
  }

  :disabled {
    opacity: 0.5;
  }

  ${({ full }) => {
    if (full) {
      return `
          width: 100%;
        `;
    }
    return ``;
  }}
  ${({ size }) => {
    if (size === `sm`) {
      return `
          padding: 10px 20px;
          font-size: 16px;
        `;
    }
    return ``;
  }}
    ${({ shape }) => {
    if (shape === `round`) {
      return `border-radius: 28px;`;
    }
    if (shape === `circle`) {
      return `
        aspect-ratio: 1;
        border-radius: 100%;
        padding: 24px;
        width: 136px;
        height: 136px;
        line-height: 1;
        `;
    }
    return ``;
  }}
    ${({ color = `blue`, outline }) => {
    if (color === `mint`) {
      return MintDefault;
    }
    if (color === `blue`) {
      return outline ? BlueOutline : BlueDefault;
    }
    if (color === `yellow`) {
      return outline ? YellowOutline : YellowDefault;
    }
    if (color === `white`) {
      return outline ? WhiteOutline : WhiteDefault;
    }
    if (color === `grey`) {
      return outline ? GreyOutline : GreyDefault;
    }
    if (color === `gray`) {
      return outline ? GrayOutline : GrayDefault;
    }
    if (color === `white-grey`) {
      return outline ? WhiteGreyOutline : WhiteGreyDefault;
    }
    if (color === `white-sky`) {
      return WhiteSkyOutline;
    }
    return ``;
  }}
    ${({ width }) => {
    if (width) {
      return `
          width: 100%;
          max-width: ${width}px;
        `;
    }
    return ``;
  }}
`;

export default Button;

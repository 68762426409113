import { GatsbyLinkProps, Link } from 'gatsby';
import React, { FC } from 'react';

type LinkSafeProps = Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'>;

// 외부링크는 a 태그로 처리
const LinkSafe: FC<LinkSafeProps> = ({ to, ...props }) =>
  /^(http|https|tel|mailto)/.test(to) ? (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a href={to} {...props} />
  ) : (
    <Link to={to} {...props} />
  );

export default LinkSafe;

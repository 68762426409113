import Img404 from '@/assets/img/404_img@2x.png';
import HeaderLogo from '@/assets/img/logo_active@2x.png';
import Button from '@/components/Button';
import Container from '@/components/Container';
import Footer from '@/components/Footer';
import Image from '@/components/Image';
import SiteMap from '@/components/SiteMap';
import { Tit } from '@/components/Titles';
import GlobalStyle from '@/GlobalStyle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { Link, PageProps } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;

  ${breakpoint(`tablet`)} {
    height: auto;
    footer {
      display: none;
    }
  }
`;
const Header = styled.header`
  padding: 50px 0;

  ${breakpoint(`tablet`)} {
    padding: 30px 0;
  }
`;

const Logo = styled.h1`
  display: block;
  width: 110px;
  margin: 0 auto;

  ${breakpoint(`tablet`)} {
    width: 95px;
  }
`;

const LogoLink = styled((props) => <Link {...props} />)`
  display: block;
  width: 100%;
  padding-top: 54.55%;
  background: url(${HeaderLogo}) no-repeat center center;
  background-size: contain;
  position: relative;
`;

const Content = styled.div`
  max-width: 1160px;
  padding: 0 20px;
  margin: 0 auto;
  min-height: calc(100vh - 360px);
  display: block;
  display: flex;
  align-items: center;

  dt,
  dd {
    padding: 30px 50px;
  }

  h2 {
    font-size: 180px;
    line-height: 1;
    display: block;
    color: #1cabe2;
    margin-bottom: 35px;
  }
  p {
    margin-top: 16px;
    margin-bottom: 36px;
  }

  dd {
    img {
      max-width: 100%;
    }
  }

  ${breakpoint(1160)} {
    h2 {
      font-size: 15.52vw;
    }

    dt,
    dd {
      padding: 0 4.31vw;
    }
  }
  ${breakpoint(`tablet`)} {
    height: auto;
    min-height: 0;

    padding-bottom: 50px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    max-width: 320px;

    dt,
    dd {
      width: 100%;
      padding: 0;
    }

    dd {
      margin-bottom: 40px;
      text-align: center;
      img {
        max-width: 240px;
      }
    }

    h2 {
      display: none;
    }
    ${Tit} {
      font-size: 20px;
    }
    p {
      margin-top: 8px;
    }
  }
`;
const SiteMapContainer = styled(Container)`
  border-top: 1px solid #b2def1;
  margin: 40px auto 72px;
`;

const NotFound: React.FC<PageProps> = ({ location }) => {
  const [isMount, setIsMount] = useState(false);

  useEffect(() => {
    setIsMount(true);
  }, []);

  if (!isMount) {
    return null;
  }

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <Header>
          <Logo>
            <LogoLink to="/">
              <span className="for-a11y">유니세프</span>
            </LogoLink>
          </Logo>
        </Header>
        <Content>
          <dt>
            <h2>404</h2>
            <Tit size="s2" color="sky">
              페이지를 찾을 수 없습니다
            </Tit>
            <p>
              페이지 주소가 잘못 입력되었거나, <br />
              변경 또는 삭제되어 찾을 수 없습니다.
              <br />
              <br />
              입력하신 주소가 정확한 지 다시 한번 확인해 주세요.
            </p>

            <Link to="/">
              <Button full outline>
                메인 화면 바로가기
              </Button>
            </Link>
          </dt>
          <dd>
            <Image pcSrc={Img404} mobileSrc={Img404} alt="" />
          </dd>
        </Content>
        <SiteMapContainer>
          <SiteMap />
        </SiteMapContainer>
        <Footer />
      </Wrapper>
    </>
  );
};

export default NotFound;

export interface Route {
  id: string | number;
  link: string;
  name: string;
  children?: Route[];
  hidden?: boolean;
  colIndex?: number;
  newtab?: 'Y' | 'N';
}

export interface ServerRoute {
  id: string;
  link: string;
  level: string;
  menuUrl: string;
  menuCode: string;
  menuName: string;
  menuColumnOrder?: string;
  offspring?: ServerRoute[];
  openYn: string;
}

export const serverToRoute: (menu: ServerRoute) => Route = (menu) => ({
  id: menu.menuCode,
  link: menu.menuUrl,
  name: menu.menuName,
  colIndex: menu.menuColumnOrder ? Number(menu.menuColumnOrder) : 0,
  children: menu.offspring?.map(serverToRoute),
  newtab: menu.openYn,
});

// 메뉴관리에 포함되지 않을 고정 라우팅
export const ROUTES: Route[] = [
  {
    id: 2,
    name: `후원과 참여`,
    link: `/involve/donors/field-trip`,
    hidden: true,
    children: [
      {
        id: 22,
        name: `개인이 후원`,
        link: `/involve/individual/pledge`,
        hidden: true,
        children: [
          {
            id: 223,
            name: `생명을 구하는 선물`,
            link: `/involve/individual/ig`,
            hidden: true,
            children: [
              {
                id: 2231,
                name: `후원신청서`,
                link: `/involve/individual/ig/form`,
                hidden: true,
              },
            ],
          },
          {
            id: 225,
            name: `기념기부`,
            link: `/involve/individual/memory`,
            hidden: true,
            /*
            children: [
              {
                id: 2251,
                name: `혼자서 기념기부`,
                link: `/involve/individual/memory`,
                hidden: true,
              },
              {
                id: 2252,
                name: `같이 하는 기념기부`,
                link: `/involve/individual/together`,
                hidden: true,
              },
            ],
          */
          },
          {
            id: 227,
            name: `Party for UNICEF`,
            link: `/involve/individual/party-for-unicef-detail`,
            hidden: true,
          },
        ],
      },
      {
        id: 23,
        name: `단체에서 후원`,
        link: `/involve/corporate-and-organization/social-contribution`,
        hidden: true,
        children: [
          {
            id: 231,
            name: `기업 · 기관 사회공헌`,
            link: `/involve/corporate-and-organization/social-contribution`,
            hidden: true,
            children: [
              {
                id: 2311,
                name: `필란트로피`,
                link: `/involve/corporate-and-organization/philanthropie`,
                hidden: true,
              },
              {
                id: 2312,
                name: `파트너십`,
                link: `/involve/corporate-and-organization/partnership`,
                hidden: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: `유니세프 소개`,
    link: `/about-us/unicef/introduction`,
    hidden: true,
    children: [
      {
        id: 31,
        name: `유니세프`,
        link: `/about-us/unicef/introduction`,
        hidden: true,
        children: [
          {
            id: 311,
            name: `미션과 가치`,
            link: `/about-us/unicef/mission`,
            hidden: true,
            children: [
              {
                id: 3111,
                name: `아동권리협약`,
                link: `/about-us/unicef/mission/convention-on-the-rights-of-the-child`,
                hidden: true,
              },
              {
                id: 3112,
                name: `지속가능발전목표`,
                link: `/about-us/unicef/mission/sustainable-development-goals`,
                hidden: true,
              },
            ],
          },
        ],
      },
      {
        id: 32,
        name: `투명한 유니세프`,
        link: `/about-us/clear/fund/unicef-kr`,
        hidden: true,
        children: [
          {
            id: 321,
            name: `기금사용처`,
            link: `/about-us/clear/fund/unicef`,
            hidden: true,
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name: `후원하기`,
    link: `/donate`,
    hidden: true,
    children: [
      {
        id: 41,
        name: `후원완료`,
        link: `/donate/complete`,
      },
    ],
  },
  {
    id: 5,
    name: `#ENDviolence(en)`,
    link: `/what-we-do/for-every-child/endviolence/en`,
    hidden: true,
    children: [
      {
        id: 51,
        name: `donate`,
        link: `/what-we-do/for-every-child/endviolence/en/donate`,
      },
      {
        id: 52,
        name: `complete`,
        link: `/what-we-do/for-every-child/endviolence/en/donate/complete`,
      },
    ],
  },
  {
    id: 6,
    name: `로그인`,
    link: `/auth/login`,
    hidden: true,
    children: [
      {
        id: 61,
        name: `아이디/비밀번호 찾기`,
        link: `/auth/find-id`,
      },
      {
        id: 62,
        name: `아이디/비밀번호 찾기`,
        link: `/auth/find-pw`,
      },
      {
        id: 63,
        name: `아이디/비밀번호 찾기`,
        link: `/auth/find-id/result`,
      },
      {
        id: 64,
        name: `아이디/비밀번호 찾기`,
        link: `/auth/find-pw/result`,
      },
    ],
  },
  {
    id: 7,
    name: `회원가입`,
    link: `/auth/join`,
    hidden: true,
    children: [
      {
        id: 72,
        name: `정보입력`,
        link: `/auth/join/form`,
      },
      {
        id: 73,
        name: `회원가입 완료`,
        link: `/auth/join/complete`,
      },
    ],
  },
  {
    id: 8,
    name: `FAQ`,
    link: `/faq`,
    hidden: true,
  },
  {
    id: 9,
    name: `오시는 길`,
    link: `/location`,
    hidden: true,
  },
  {
    id: 1000,
    name: `개인정보처리방침`,
    link: `/privacy`,
    hidden: true,
  },
  {
    id: 1001,
    name: `위탁업체`,
    link: `/privacy-corp`,
    hidden: true,
  },
  {
    id: 1002,
    name: `영상정보처리기기 운영·관리 방침`,
    link: `/cctv-policy`,
    hidden: true,
  },
  {
    id: 1003,
    name: `이용약관`,
    link: `/service`,
    hidden: true,
  },
  {
    id: 1004,
    name: `하는일`,
    link: `/what-we-do/for-every-child/where-we-work`,
    hidden: true,
    children: [
      {
        id: 10041,
        name: `한국아동권리 증진`,
        link: `/what-we-do/advocacy-for-children/crc-implementation`,
        hidden: true,
        children: [
          {
            id: 100411,
            name: `권리교육`,
            link: `/what-we-do/advocacy-for-children/child-rights-education`,
            hidden: true,
            children: [
              {
                id: 1004111,
                name: `신청하기`,
                link: `/what-we-do/advocacy-for-children/apply`,
                hidden: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 8,
    name: `마이페이지`,
    link: `/mypage`,
    hidden: true,
    children: [
      {
        id: 81,
        name: `메인`,
        link: `/mypage`,
        hidden: true,
      },
      {
        id: 82,
        name: `개인정보`,
        link: `/mypage/my-info`,
        hidden: true,
      },
      {
        id: 83,
        name: `후원내역`,
        link: `/mypage/support-history`,
        hidden: true,
        children: [
          {
            id: 831,
            name: `후원내역 연결하기`,
            link: `/mypage/support-history/connect`,
            hidden: true,
          },
          {
            id: 832,
            name: `후원내역 연결하기`,
            link: `/mypage/support-history/connect-error`,
            hidden: true,
          },
        ],
      },
      {
        id: 84,
        name: `후원 및 결제정보`,
        link: `/mypage/support-info`,
        hidden: true,
      },
      {
        id: 85,
        name: `기부금 영수증`,
        link: `/mypage/my-receipt`,
        hidden: true,
      },
      {
        id: 86,
        name: `발송현황`,
        link: `/mypage/reward-list`,
        hidden: true,
      },
      {
        id: 87,
        name: `1:1문의`,
        link: `/mypage/onetoone`,
        hidden: true,
      },
      {
        id: 88,
        name: `웹사이트 회원탈퇴`,
        link: `/mypage/drop`,
        hidden: true,
      },
    ],
  },
];

const getRoutePathFromUri: (
  uri: string,
  routes?: Route[],
  path?: Route[],
) => Route[] = (uri, routes = [], path = []) => {
  for (const route of routes) {
    const foundChildren =
      route.children &&
      getRoutePathFromUri(uri, route.children, [...path, route]);
    if (foundChildren?.length) {
      return foundChildren;
    }
    if (route.link.replace(/\/$/, ``) === uri) {
      return [...path, route];
    }
  }
  return [];
};

// uri를 기반으로 Breadcrumb 경로 생성하는 함수
export const getRoutePath: (
  uri: string,
  routes?: Route[],
  path?: Route[],
) => Route[] = (uri, routes = [], path = []) => {
  let finalUri = uri;
  let parts = finalUri.split(`/`);
  let last = parts[parts.length - 1];
  // 마지막 부분이 숫자면
  if (/^\d+$/.test(last)) {
    // 제거
    finalUri = finalUri.substr(0, finalUri.length - (last.length + 1));
  }
  parts = finalUri.split(`/`);
  last = parts[parts.length - 1];
  // 남은 마지막 부분이 page면
  if (last === `page`) {
    // 제거
    finalUri = finalUri.substr(0, finalUri.length - (last.length + 1));
  }

  return getRoutePathFromUri(finalUri, routes, path);
};

export default ROUTES;

import LinkSafe from '@/components/LinkSafe';
import { Route, ServerRoute, serverToRoute } from '@/config/routes';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface SiteMapProps {
  routePath?: Route[];
}
const PrimaryItem = styled.div`
  position: absolute;
  left: 0;
  top: 40px;
  ${breakpoint(1024)} {
    position: relative;
    top: 0;
    margin-bottom: 24px;
  }
`;
const MenuSection = styled.div`
  font-size: 0;
  position: relative;
  padding-top: 40px;
  padding-left: 192px;
  & + & {
    margin-top: 8px;
    border-top: 1px solid #b2def1;
  }
  ${breakpoint(1024)} {
    padding-left: 0;
    padding-top: 24px;
  }
`;
const ActiveLink = styled(({ ...props }) => <LinkSafe {...props} />)``;
const PrimaryLink = styled(ActiveLink)`
  font-weight: bold;
  font-size: 20px;
  color: #1cabe2;
  ${breakpoint(640)} {
    font-size: 16px;
  }
`;
const SecondaryList = styled.div`
  display: inline-block;
  width: 160px;
  vertical-align: top;
  margin-bottom: 32px;
  ${breakpoint(1024)} {
    width: 25%;
    margin-bottom: 24px;
  }
  ${breakpoint(640)} {
    width: 50%;
  }
`;
const SecondaryItem = styled.div``;
const SecondaryLink = styled(ActiveLink)`
  display: block;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.8px;
  &:hover {
    color: #1cabe2;
  }
  ${breakpoint(640)} {
    font-size: 12px;
  }
`;
const TertiaryList = styled.ul`
  list-style: none;
  margin: 12px 0 0 0;
`;
const TertiaryItem = styled.li`
  & + & {
    margin-top: 4px;
  }
`;
const TertiaryLinkActive = css`
  color: #2d2926 !important;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 8px;
    background: #b2def1;
    position: absolute;
    left: 0;
    bottom: 4px;
    z-index: -1;
  }
`;
const TertiaryLink = styled((props) => <Link {...props} />)<{
  active?: boolean;
}>`
  padding: 5px 0;
  color: #2d2926;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.8px;
  position: relative;
  z-index: 1;
  &:hover {
    ${TertiaryLinkActive}
  }
  ${({ active }) => active && TertiaryLinkActive}
  ${breakpoint(640)} {
    font-size: 12px;
  }
`;

const SiteMap: FC<SiteMapProps> = ({ routePath }) => {
  const {
    allMenu: { nodes: serverMenus },
  } = useStaticQuery<{
    allMenu: { nodes: ServerRoute[] };
  }>(graphql`
    query {
      allMenu {
        nodes {
          id
          level
          menuCode
          menuName
          menuUrl
          openYn
          offspring {
            level
            menuCode
            menuParentCode
            menuColumnOrder
            menuName
            menuUrl
            openYn
            offspring {
              level
              menuCode
              menuName
              menuParentCode
              menuUrl
              openYn
            }
          }
        }
      }
    }
  `);
  const menus = serverMenus.map(serverToRoute);
  return menus?.map((primary) => (
    <MenuSection>
      <PrimaryItem key={primary.id}>
        <PrimaryLink
          to={primary.link}
          active={routePath?.[1]?.id === primary.id}
          target={primary.newtab === `Y` ? `_blank` : null}
        >
          {primary.name}
        </PrimaryLink>
      </PrimaryItem>
      {primary.children &&
        primary.children.map((secondary) => (
          <SecondaryList>
            <SecondaryItem key={secondary.id}>
              <SecondaryLink
                to={secondary.link}
                active={routePath?.[2]?.id === secondary.id}
                target={secondary.newtab === `Y` ? `_blank` : null}
              >
                {secondary.name}
              </SecondaryLink>
            </SecondaryItem>
            {secondary.children && (
              <TertiaryList>
                {secondary.children.map((tertiary) => (
                  <TertiaryItem key={tertiary.id}>
                    <TertiaryLink
                      to={tertiary.link}
                      active={routePath?.[3]?.id === tertiary.id}
                      target={tertiary.newtab === `Y` ? `_blank` : null}
                    >
                      {tertiary.name}
                    </TertiaryLink>
                  </TertiaryItem>
                ))}
              </TertiaryList>
            )}
          </SecondaryList>
        ))}
    </MenuSection>
  ));
};

export default SiteMap;
